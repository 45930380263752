<template>
  <v-row dense align="center">
    <v-col
      v-if="userAccess.canUpdate && showButton.includes('UPLOAD')"
      cols="auto"
    >
      <v-menu
        offset-y
        v-if="dataTypeProp === 'customer'
          || dataTypeProp === 'transporter'
          || dataTypeProp === 'selection_info'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            elevation="1"
            small
            :disabled="disableButtonUpload"
            :loading="uploadingCsv"
          >
            {{$_strings.selectioninfo.button.UPLOAD_CSV}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in dataTypeOptions"
            :key="index"
            link
            @click="handleUploadCsvDropdownClick(item.dataType)"
          >
            <v-list-item-title for="csv-upload" v-text="item.label"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-else
        color="primary"
        elevation="1"
        small
        :disabled="disableButtonUpload"
        :loading="uploadingCsv"
        @click="handleUploadCsvDropdownClick(dataTypeProp)"
      >
        {{$_strings.selectioninfo.button.UPLOAD_CSV}}
      </v-btn>
      <input
        id="csv-upload"
        :disabled="uploadingCsv"
        ref="fileInput"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @change="uploadCsv"
      />
    </v-col>
    <v-col cols="auto" v-if="showButton.includes('TEMPLATE')">
      <v-menu
        offset-y
        v-if="dataTypeProp === 'customer'
          || dataTypeProp === 'transporter'
          || dataTypeProp === 'selection_info'
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            elevation="1"
            small
            :loading="downloadingCsv"
          >
            {{$_strings.selectioninfo.button.TEMPLATE_CSV}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in dataTypeOptions"
            :key="index"
            link
            @click="handleDownloadCsvDropdownClick(item.dataType)"
          >
            <v-list-item-title v-text="item.label"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-else
        color="primary"
        dark
        elevation="1"
        small
        :loading="downloadingCsv"
        @click="downloadCsvTemplate(dataTypeProp)"
      >
        {{$_strings.selectioninfo.button.TEMPLATE_CSV}}
      </v-btn>
    </v-col>
    <v-col cols="auto" v-if="showButton.includes('STATUS')">
      <UploadedCsvStatusDialog :importType="importTypeProp" :dataType="dataTypeProp" />
    </v-col>
  </v-row>
</template>

<script>

const UploadedCsvStatusDialog = () => import('./UploadedCsvStatusDialog');
const { exportCSVFile } = require('../../helper/csvGenerator');

export default {
  name: 'MasterDataCsvUpload',
  data() {
    return {
      isUploadedCsvStatusDialogVisible: false,
      file: null,
      uploadingCsv: false,
      dataType: null,
      downloadingCsv: false,
    };
  },
  components: {
    UploadedCsvStatusDialog,
  },
  props: {
    dataTypeProp: String,
    uploadCsvProp: Function,
    downloadTemplateCsvProp: Function,
    importTypeProp: String,
    disableButtonUpload: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Array,
      default: () => ['UPLOAD', 'TEMPLATE', 'STATUS'],
    },
  },

  computed: {
    dataTypeOptions() {
      return this.$_strings.csvUpload.csvDataTypeOptions.filter((i) => i.databaseType === this.dataTypeProp);
    },
  },
  methods: {
    async handleUploadCsvDropdownClick(command) {
      await this.$refs.fileInput.click();
      this.dataType = command;
    },
    handleDownloadCsvDropdownClick(command) {
      this.downloadCsvTemplate(command);
    },
    downloadCsvTemplate(type) {
      this.downloadingCsv = true;
      this.downloadTemplateCsvProp(type)
        .then((result) => {
          const { column, filename } = result.data;
          exportCSVFile(null, [[...column]], filename);
        }).finally(() => {
          this.downloadingCsv = false;
        });
    },
    fetchList() {
      // ADD SET TIMEOUT FOR WAITING DATA ALREADY EXISTS IN DATABASES
      this.$root.$loading.show();
      setTimeout(() => {
        this.$emit('fetchList');
        this.$root.$loading.hide();
      }, 1000);
    },
    uploadCsv() {
      this.uploadingCsv = true;
      const formData = new FormData();
      formData.append('file', this.$refs.fileInput.files[0]);
      formData.append('type', this.dataType || this.dataTypeProp);
      this.uploadCsvProp(formData)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.csvUpload.uploadCsvSuccessMessage);
          this.fetchList();
          const input = this.$refs.fileInput;
          input.type = 'text';
          input.type = 'file';
        }).finally(() => {
          this.uploadingCsv = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item-title{
    cursor: pointer;
}
input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
</style>
